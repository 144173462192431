<template>
  <base-drop-down-menu-button
    ref="projectFilterMenu"
    :close-on-content-click="false"
  >
    <template v-slot:iconName>
      {{ iconFilter }}
    </template>
    <template v-slot:commands>
      <!-- Sort by Status -->
      <v-card-title>
        <span :class="titleTextClass">{{ title }}</span></v-card-title
      >
      <v-divider></v-divider>
      <v-card-text>
        <v-checkbox
          dense
          hide-details
          v-model="selectAllOptions"
          :label="selectAllLabel"
          @change="toggleSelectAll()"
        ></v-checkbox>
        <v-checkbox
          class="ml-6 mb-0"
          dense
          hide-details
          v-for="option in options"
          :key="option"
          :value="option"
          v-model="selectedOptions"
          :label="option"
        ></v-checkbox>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-button
          :disabled="!selectedOptions.length"
          @click="onFilterApplied()"
          >Done</base-button
        >
      </v-card-actions>
    </template>
  </base-drop-down-menu-button>
</template>

<script>
// design
import { iconFilter } from "@/design/icon/iconConst";
import { displayTypes, fontEmphasis, Text } from "@/design/text/Text";

// model
import {
  Color,
  colorMD,
  variantNumber,
  variantType
} from "@/design/colors/Color";
import { eventNames } from "@/model/common/events/eventConst";
import {
  projectLocalStorageKeys,
  projectStates
} from "@/model/workflow/project/projectModel";

// utils
import { getLocalStorageItem } from "@/utils/localStorageUtility";

export default {
  name: "ProjectFilterMenu",
  components: {
    BaseDropDownMenuButton: () =>
      import("@/components/shared/base/BaseDropDownMenuButton"),
    BaseButton: () => import("@/components/shared/base/BaseButton")
  },
  data() {
    return {
      iconFilter: iconFilter,
      titleTextClass: new Text(
        new Color(colorMD.indigo, variantType.lighten, variantNumber.n1),
        displayTypes.title,
        fontEmphasis.bold
      ).getClassText(),
      selectedOptions: this.projectStateNames(),
      selectAllOptions: true,
      options: this.projectStateNames()
    };
  },

  computed: {
    title() {
      return "Filter By Status";
    },
    selectAllLabel() {
      return "All";
    }
  },

  methods: {
    /**
     * Toggle When select All Projects is Clicked
     */
    toggleSelectAll() {
      if (this.selectAllOptions) {
        this.selectedOptions = [...this.options];
      } else {
        this.selectedOptions = [];
      }
    },

    /**
     * Action on Filter Applied
     */
    onFilterApplied() {
      // Close the menu
      this.$refs.projectFilterMenu?.closeMenu();
    },

    /**
     * get project Status Names
     * @return {string[]}
     */
    projectStateNames() {
      return projectStates.map(el => el.name);
    }
  },

  watch: {
    selectedOptions() {
      this.selectAllOptions =
        this.selectedOptions.length === this.projectStateNames().length;
      this.$emit(eventNames.optionChanged, this.selectedOptions);
    }
  },

  mounted() {
    this.selectedOptions =
      JSON.parse(
        getLocalStorageItem(projectLocalStorageKeys.projectFilterOption)
      ) ?? this.projectStateNames();
  }
};
</script>

<style scoped></style>
